<template>
  <div id="details">
    <top :name="text" />
    <!-- 商品详情轮播 -->
    <div class="lunbo_div">
      <van-swipe class="my-swipe" :autoplay="2000" indicator-color="gray">
        <van-swipe-item v-for="(item, index) in data.img" :key="index">
          <img
            alt="cover"
            :src="item"
            class="lunbo"
            @click="swiperImgClick()"
          />
        </van-swipe-item>
      </van-swipe>
      <div class="posi">
        <p style="font-size: 0.36rem">￥{{ data.price }}元 + {{data.integral}}积分</p>
        <div>
          <img src="../assets/fenxiang.png" alt="" />
          <p>分享</p>
        </div>
      </div>
    </div>
    <div
      style="
        background: white;
        margin-bottom: 0.03rem;
        padding: 0.2rem 0 0.2rem 0;
      "
    >
      <p style="font-weight: bold" class="p">
        {{ data.name }}
      </p>
      <!-- <p
        style="padding: 0.04rem 0.04rem 0.04rem 0.2rem; margin: 0.2rem auto               text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0.2rem auto;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;"
        class="p jl"
      >
        {{ data.introduce }}
      </p> -->
      <p style="color: gray" class="p">销量：{{ data.sales }}</p>
    </div>
    <div class="fahuo">
      <p style="color: gray; font-size: 0.28rem">发货</p>
      <p>购买后平台会第一时间发货，具体发货时间以实际为准</p>
    </div>
    <!-- <div class="fahuo" @click="is_showBase()">
      <p style="color: gray; font-size: 0.28rem">已选规格</p>
      <p>华为官方至尊专享版 1/件</p>
      <p
        style="color: gainsboro; text-align: right; flex: 1; font-size: 0.4rem"
      >
        >
      </p>
    </div> -->
    <van-divider :style="{ borderColor: 'rgb(219 219 219)' }"
      >商品详情</van-divider
    >
    <div class="xiangqing_img" v-html="data.detailp">
      <!--  -->
    </div>
    <!-- 商品规格 -->
    <!--  sku   sku 数据 -->
    <!--  goods 商品信息 -->
    <!--  goods-id   商品 id -->
    <!--  quota 商品信息 -->
    <!--  quota-used   已经购买过的数量 -->
    <!--  hide-stock 	是否显示商品剩余库存 -->
    <!--  message-config   留言相关配置 -->
    <!--  -->
    <van-sku
      v-model="showBase"
      :sku="skuData.sku"
      :goods="skuData.goods_info"
      :goods-id="skuData.goods_id"
      :hide-stock="skuData.sku.hide_stock"
      :disable-soldout-sku="is_show_sku"
      :quota="skuData.quota"
      :quota-used="skuData.quota_used"
      :initial-sku="initialSku"
      :reset-stepper-on-hide="ssw"
      :reset-selected-sku-on-hide="iss"
      disable-stepper-input
      :close-on-click-overlay="closeOnClickOverlay"
      :custom-sku-validator="customSkuValidator"
      @buy-clicked="onBuyClicked"
      @add-cart="onAddCartClicked"
      @stepper-change="purchase"
      @sku-prop-selected="onclickshuxing"
      @sku-selected="gaibian"
      ref="vanSku"
    >
      <!-- props.selectedSkuComb.price*geshu -->
      <template #sku-header-price="props">
        <div class="van-sku__goods-price">
          <span class="van-sku__price-symbol">￥</span
          ><span class="van-sku__price-num">{{
            props.price * 100 * geshu
          }}</span>
        </div>
      </template>
    </van-sku>
    <!-- 底部购买 -->
    <van-goods-action>
      <van-goods-action-icon
        icon="chat-o"
        text="客服"
        color="#ee0a24"
        @click="kefu"
      />
      <van-goods-action-icon icon="cart-o" text="购物车" />
      <van-goods-action-icon icon="shop-o" text="店铺" />
      <van-goods-action-button
        type="warning"
        text="加入购物车"
        @click="is_showBase()"
      />
      <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="is_showBase()"
      />
    </van-goods-action>
  </div>
</template>

<script>
import Vue from "vue";
import top from "../components/filltop.vue";
import skuData from "../js/data.js";
import { ImagePreview } from "vant";
export default {
  components: {
    top,
  },
  data() {
    return {
      geshu: 1,
      ssw: true,
      iss: true,
      text: "商品详情",
      skuData: skuData,
      is_show_sku: true,
      showBase: false,
      showCustom: false,
      showStepper: false,
      showSoldout: false,
      str: "",
      closeOnClickOverlay: true,
      initialSku: {
        // s1:47741,
        // s2:47742,
        // s3: 33165,
        selectedProp: {
          33163: [47741],
          33164: [47742],
          33165: [47745],
        },
      },
      selectedSku: {
        // s1: 20071,
        // s2: 20076,
      },
      customSkuValidator: () => "请完善商品规格信息!",
      data: {},
      tive: [],
      index_item: 0,
      parameter_arr: [],
    };
  },
  activated() {
    console.log("缓存函数");
  },
  beforeRouteLeave(to, from, next) {
    // // 判断是否是跳转到预订界面 缓存起来
    if (to.fullPath != "/further/submission") {
      this.tive = [];
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "index" || from.name == "productlist") {
        console.log(to, from);
        vm.data = vm.$route.params.success.data.data;
        vm.skuData = vm.$route.params.success.data.data.secifications;
        // console.log(vm.skuData)
        var attr_arr = [];
        // 前台处理购物车
        // 默认选择第一个
        vm.data.secifications.sku.tree.forEach((item, index) => {
          vm.$set(vm.initialSku, item.k_s, item.v[0].id);
          attr_arr.push(item.v[0]);
          var b = attr_arr[index].attrid + ":" + attr_arr[index].id;
          vm.tive.push(b);
        });
        vm.str = vm.tive.join(";") + ";";
        console.log(vm.str);
        vm.skuData.goods_info.picture = vm.data.img_details[vm.str];
      }
      // 通过“vm”访问组件实例` //可以通过vm.name去访问data里面的name属性，跟this.name一样效果
    });
  },
  methods: {
    // 商品详情轮播大图预览
    swiperImgClick() {
      ImagePreview(this.data.img);
    },
    // 数量增加
    purchase(event) {
      this.geshu = event;
    },
    // 弹出规格框时重置一次
    is_showBase() {
      this.showBase = true;
    },

    onBuyClicked(skuData) {
      var bt = [];
      for (var i = 1; i < 6; i++) {
        if (skuData.selectedSkuComb["s" + i] == undefined) {
          // 未定义  跳出循环
          break;
        } else {
          // 有数据 追加数组
          this.parameter_arr[i - 1] = {
            ["s" + i]: skuData.selectedSkuComb["s" + i],
          };
          bt.push(this.data.secifications.sku.tree[i - 1].k_id);
        }
      }
      console.log(this.parameter_arr);
      // 立即购买  mock/lookup
      this.$https(
        "mock/lookup",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&stock_num=" +
          skuData.selectedNum +
          "&commodity_id=" +
          skuData.goodsId +
          "&attrid=" +
          bt +
          "&parameter=" +
          JSON.stringify(this.parameter_arr),
        "post"
      ).then((res) => {
        if (res.data.code == "000000") {
          // 缓存请求参数 预订界面要使用
          sessionStorage.setItem("stock_num", skuData.selectedNum);
          sessionStorage.setItem("commodity_id", skuData.goodsId);
          sessionStorage.setItem("attrid", bt);
          sessionStorage.setItem(
            "parameter",
            JSON.stringify(this.parameter_arr)
          );
          // submission
          this.$router.push({
            name: "submission",
            params: { bayget: JSON.stringify(res.data.data) },
          });
        } else {
          this.$toast.fail(res.data.msg);
        }
        console.log("res", res);
      });
    },
    onAddCartClicked(data) {
      var bt = [];
      for (var i = 1; i < 6; i++) {
        if (data.selectedSkuComb["s" + i] == undefined) {
          // 未定义  跳出循环
          break;
        } else {
          // 有数据 追加数组
          this.parameter_arr[i - 1] = {
            ["s" + i]: data.selectedSkuComb["s" + i],
          };
          bt.push(this.data.secifications.sku.tree[i - 1].k_id);
        }
      }
      console.log(bt, "bt");
      console.log(data, "data");
      this.$https(
        "mall/addceat",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&stock_num=" +
          data.selectedNum +
          "&commodity_id=" +
          data.goodsId +
          "&attrid=" +
          bt +
          "&parameter=" +
          JSON.stringify(this.parameter_arr),
        "post"
      ).then((res) => {
        this.$toast.success(res.data.msg);
      });
    },
    onclickshuxing(e) {
      console.log(e, "切换");
    },
    kefu() {
      // 客服
    },
    gaibian(e) {
      console.log("自动输出", e);
      if (e.selectedSkuComb == null) {
        // this.$refs.vanSku.resetSelectedSku();
        // console.log("进入null",e);
        //
        var attr_up = [],
          ip = [];
        // for (var i = 0; i < this.data.secifications.sku.tree.length; i++) {
        //   this.$set(
        //     this.initialSku,
        //     this.data.secifications.sku.tree[i].k_s,
        //     this.data.secifications.sku.tree[i].v[0].id
        //   );
        //   attr_up.push(this.data.secifications.sku.tree[i].v[0]);
        //   var b = attr_up[i].attrid + ":" + attr_up[i].id;
        //   ip.push(b);
        //   this.tive = ip;
        // }
        // for (var n = 0; n < ip.length - 1; n++) {
        //   var v = ip[n] + ";" + ip[n + 1] + ";";
        // }
        // this.skuData.goods_info.picture = this.data.img_details[v];
      } else {
        // console.log("进入非空");
        //
        this.tive.forEach((item, index) => {
          if (item.indexOf(e.skuValue.attrid) == 0) {
            this.tive.splice(index, 1, e.skuValue.attrid + ":" + e.skuValue.id);
          }
        });
        var str = "";
        str = this.tive.join(";") + ";";
        this.skuData.goods_info.picture = this.data.img_details[str];
      }
    },
  },
  created(e) {},
  mounted(e) {
    this.data = this.$route.params.success.data.data;
    this.skuData = this.$route.params.success.data.data.secifications;
    // console.log(this.skuData)
    var attr_arr = [];
    // 前台处理购物车
    // 默认选择第一个
    this.data.secifications.sku.tree.forEach((item, index) => {
      this.$set(this.initialSku, item.k_s, item.v[0].id);
      attr_arr.push(item.v[0]);
      var b = attr_arr[index].attrid + ":" + attr_arr[index].id;
      this.tive.push(b);
    });
    var str = "";
    str = this.tive.join(";") + ";";
    this.skuData.goods_info.picture = this.data.img_details[str];
  },
};
</script>


<style scoped>
.posi {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  padding: 0.06rem 0.2rem 0 0.2rem;
  background: #008acb;
  display: flex;
  font-size: 0.26rem;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.posi div img {
  width: 0.4rem;
  height: auto;
}
#details {
  background: #efefef;
}
.jl {
  width: 94%;
  background: #2a5cbe63;
  color: #550b61;
}
.p {
  font-size: 0.28rem;
  padding: 0 0.2rem 0 0.2rem;
  margin-bottom: 0.2rem;
}
.lunbo_div {
  width: 100%;
  height: auto;
  position: relative;
}
.lunbo {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center center;
}
.fahuo {
  width: 100%;
  display: flex;
  height: auto;
  margin-bottom: 0.01rem;
  background: white;
  align-content: center;
  padding: 0.2rem;
  font-size: 0.26rem;
}
.fahuo :nth-child(1) {
  margin-right: 0.2rem;
}
.xiangqing_img {
  width: 100%;
  height: auto;
}
</style>