export default { 
    sku: {
      // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
    // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
      tree: [
        {
          k: '颜色',
          k_id: '1',
          v: [
            {
              id: '10',
              name: '天蓝色',
              imgUrl:
                'http://mallimages.csair.com/album/2021/1/6/ef9fec6d-94bd-4e8e-9ab6-e972b2a56bd9.jpg'
            },
            {
              id: '11',
              name: '白色',
              imgUrl:
                'http://mallimages.csair.com/album/2021/1/6/26012206-2dfb-441d-a6bf-73953aecb2df.jpg'
            }
          ],
          
          k_s: 's1',
          count: 2
        },
        {
          k: '规格',
          k_id: '2',
          v: [
            {
              id: '12',
              name: '5+258',
            },
            {
              id: '13',
              name: '4+128'
            }
          ],
          
          k_s: 's2',
          count: 2
        },
      ],
      // 所有 sku 的组合列表，如下是：白色1、白色2、天蓝色1、天蓝色2
      list: [
        {
          id: 2259,
          price: 1200, //价格     到分
          discount: 122, 
          s1: '10',
          s2: '12',
          s3: '0',
          s4: '0',
          s5: '0',  
          stock_num: 920, //库存 
          goods_id: 123123
        },
        {
          id: 2260,
          price: 110,
          discount: 112, 
          s1: '10',
          s2: '13',
          s3: '0',
          s4: '0',
          s5: '0',  
          stock_num: 200, //库存 
          goods_id: 946755
        },
        {
          id: 2260,
          price: 110,
          discount: 112, 
          s1: '11',
          s2: '13',
          s3: '0',
          s4: '0',
          s5: '0',  
          stock_num: 200, //库存 
          goods_id: 946755
        },
        {
          id: 2260,
          price: 110,
          discount: 112, 
          s1: '12',
          s2: '13',
          s3: '0',
          s4: '0',
          s5: '0',  
          stock_num: 200, //库存 
          goods_id: 946755
        },
      ],
      price: '5.00',
      stock_num: 227, // 商品总库存
      none_sku: false,  // 是否无规格商品 
      hide_stock: false  // 是否隐藏剩余库存
    },
    goods_id: '2666', 
    quota: 10, //限购数量 
    quota_used: 0,  //已经购买过的数量
    goods_info: {
      title: '测试商品A',
      picture:
        '' 
    },
    initialSku:{
            s1: "10",
            s2: "12",
            s3: "14",
            selectedNum: 3
        }
  };